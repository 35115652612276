import React, { useState } from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Spring, config } from "react-spring/renderprops"
import * as styles from "./about.module.css"
import { Container, Row, Col } from "react-bootstrap"
import ClientOnly from "../components/clientOnly"
import { useMediaQuery } from "react-responsive"
import { StaticImage } from "gatsby-plugin-image"
import Calendar from "../components/calendar"
import { Helmet } from "react-helmet"

export default function Events({ data }) {
  var about1 = "../images/about-5.jpg"
  var about2 = "../images/about-6.jpg"

  const [destination, setDestination] = useState(-1)
  const isDestination = d => {
    return d === destination
  }
  const isMobile = useMediaQuery({
    query: "(max-width: 42rem)",
  })
  //highlight-line
  return (
    <div>
      <SEO
        title="Events | Forever Essential"
        description="The Forever Essential memorial travels California to honor the lives of fallen healthcare workers."
        fbHeadline="Events | Forever Essential"
        fbDescription="Learn more about the Forever Essential memorial."
        twShare="#ForeverEssential is a traveling memorial that honors the legacies of healthcare workers who have passed away from COVID-19."
      />
      <ClientOnly>
        <Layout handlerDestination={setDestination}>
          {/* highlight-start */}
          {/* <div
            className={`section about_section ${styles.container}`}
            style={{
              background:
                "linear-gradient(rgba(255,255,255,1), rgba(143,211,215,1)",
            }}
          >
            <Container>
              <Row>
                <Col xs={{ span: 12, order: 1 }} md={{ span: 6, order: 0 }}>
                  <Spring
                    delay={300}
                    to={{
                      opacity: isDestination(0) ? 1 : 0,
                      transform: isDestination(0)
                        ? "translateX(0)"
                        : "translateX(-200px)",
                    }}
                    config={config.molasses}
                  >
                    {props => (
                      <div
                        style={{
                          ...props,
                          textAlign: "center",
                          paddingTop: isMobile ? "2%" : "100px",
                        }}
                      >
                        <div className={styles.imageContainer}>
                          <StaticImage
                            src={about1}
                            placeholder="blurred"
                            alt="Flowers"
                          />
                        </div>
                      </div>
                    )}
                  </Spring>
                  <Spring
                    delay={300}
                    to={{
                      opacity: isDestination(0) ? 1 : 0,
                      transform: isDestination(0)
                        ? "translateY(0)"
                        : "translateY(-200px)",
                    }}
                    config={config.molasses}
                  >
                    {props => (
                      <div
                        style={{
                          ...props,
                          textAlign: "center",
                          paddingTop: isMobile ? "2%" : "20px",
                          paddingBottom: "50px",
                        }}
                      >
                        <p>
                          Forever Essential is both a digital and a physical
                          memorial honoring California healthcare workers who
                          we’ve lost to COVID-19. Through this memorial, we hope
                          to transform our private pain into public dignity and
                          to carry forward the legacies of those we’ve lost. The
                          physical memorial will make stops in cities across
                          California, offering a space for our community to
                          remember and grieve.
                        </p>
                        <p>
                          PLEASE NOTE: While the memorial is open-air, please
                          remember to wear a mask and adhere to social
                          distancing guidelines during your visit.
                        </p>
                      </div>
                    )}
                  </Spring>
                </Col>
                <Col xs={{ span: 12, order: 0 }} md={{ span: 6, order: 1 }}>
                  <Spring
                    delay={300}
                    to={{
                      opacity: isDestination(0) ? 1 : 0,
                      transform: isDestination(0)
                        ? "translateX(0)"
                        : "translateX(200px)",
                    }}
                    config={config.molasses}
                  >
                    {props => (
                      <div
                        style={{
                          ...props,
                          textAlign: "center",
                          paddingTop: isMobile ? "2%" : "100px",
                        }}
                      >
                        <h2>Attend an Event</h2>
                        <div className={styles.imageContainer}>
                          <StaticImage
                            src={about2}
                            aspectRatio={1}
                            placeholder="blurred"
                            alt="Trees and Bench"
                          />
                        </div>
                      </div>
                    )}
                  </Spring>
                </Col>
              </Row>
            </Container>
          </div> */}
          {/* <div
            className={`section about_section ${styles.container}`}
            style={{
              background:
                "linear-gradient(rgba(143,211,215,1), rgba(0, 155, 165, 1))",
            }}
          >
            <Spring
              delay={300}
              to={{
                opacity: isDestination(1) ? 1 : 0,
                transform: isDestination(1)
                  ? "translateY(0)"
                  : "translateY(200px)",
              }}
              config={config.molasses}
            >
              {props => (
                <div
                  style={{
                    ...props,
                    height: "80vh"
                  }}
                >
                  <Calendar />
                </div>
              )}
            </Spring>
          </div> */}
          {/* <div
            className={`section about_section ${styles.container}`}
            style={{
              background:
                "linear-gradient(rgba(0, 155, 165, 1), rgba(143,211,215,1))",
            }}
          >
            <Spring
              delay={300}
              to={{
                opacity: isDestination(2) ? 1 : 0,
                transform: isDestination(2)
                  ? "translateY(0)"
                  : "translateY(200px)",
              }}
              config={config.molasses}
            >
              {props => (
                <div
                  style={{
                    ...props,
                  }}
                >
                  <Container
                    style={{ textAlign: "center", minHeight: "100vh" }}
                  >
                    <Row>
                      <Col>
                        <h2
                          style={{ textAlign: "center", marginBottom: "1em" }}
                        >
                          Walkthrough of the Memorial
                        </h2>
                        <div
                          className="video"
                          style={{ paddingBottom: "50px" }}
                        >
                          <Helmet>
                            <script
                              src="https://fast.wistia.com/embed/medias/phios68bsd.jsonp"
                              async
                            ></script>
                            <script
                              src="https://fast.wistia.com/assets/external/E-v1.js"
                              async
                            ></script>
                          </Helmet>
                          <div
                            className="wistia_responsive_padding"
                            style={{
                              padding: "61.04% 0 0 0",
                              position: "relative",
                            }}
                          >
                            <div
                              className="wistia_responsive_wrapper"
                              style={{
                                height: "100%",
                                left: 0,
                                position: "absolute",
                                top: 0,
                                width: "100%",
                              }}
                            >
                              <div
                                className="wistia_embed wistia_async_phios68bsd videoFoam=true"
                                style={{
                                  height: "100%",
                                  position: "relative",
                                  width: "100%",
                                }}
                              >
                                <div
                                  className="wistia_swatch"
                                  style={{
                                    height: "100%",
                                    left: 0,
                                    opacity: 0,
                                    overflow: "hidden",
                                    position: "absolute",
                                    top: 0,
                                    transition: "opacity 200ms",
                                    width: "100%",
                                  }}
                                >
                                  <img
                                    src="https://fast.wistia.com/embed/medias/phios68bsd/swatch"
                                    style={{
                                      filter: "blur(5px)",
                                      height: "100%",
                                      objectFit: "contain",
                                      width: "100%",
                                    }}
                                    alt=""
                                    aria-hidden="true"
                                    onload="this.parentNode.style.opacity=1;"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </Container>
                </div>
              )}
            </Spring>
          </div> */}
          {/* <div className={`section ${styles.galleryContainer}`}>
        <Container>
          <Row style={{textAlign:"center", justifyContent:"center"}}>
            <Col xs={12} md={6} lg={4}>
              <VisibilitySensor partialVisibility>
                {({ isVisible }) => (
                  <Spring
                    delay={300}
                    to={{
                      opacity: isVisible ? 1 : 0,
                      transform: isVisible
                        ? "translateX(0)"
                        : "translateX(-200px)",
                    }}
                    config={config.molasses}
                  >
                    {props => (
                      <div
                        style={{
                          ...props,
                          textAlign: "center",
                        }}
                      >
                        <div className={styles.imageContainer}>
                          <img
                            src={gallery1}
                            alt="About memorial"
                            width="100%"
                          ></img>
                        </div>
                      </div>
                    )}
                  </Spring>
                )}
              </VisibilitySensor>
            </Col>
            <Col xs={12} md={6} lg={4}>
              <VisibilitySensor partialVisibility>
                {({ isVisible }) => (
                  <Spring
                    delay={300}
                    to={{
                      opacity: isVisible ? 1 : 0,
                      transform: isVisible
                        ? "translateY(0)"
                        : "translateY(-100px)",
                    }}
                    config={config.molasses}
                  >
                    {props => (
                      <div
                        style={{
                          ...props,
                        }}
                        className={styles.oneThird}
                      >
                        <div className={styles.imageContainer}>
                          <img
                            src={gallery2}
                            alt="About memorial"
                            width="100%"
                          ></img>
                        </div>
                      </div>
                    )}
                  </Spring>
                )}
              </VisibilitySensor>
            </Col>
            <Col xs={12} md={6} lg={4}>
              <VisibilitySensor partialVisibility>
                {({ isVisible }) => (
                  <Spring
                    delay={300}
                    to={{
                      opacity: isVisible ? 1 : 0,
                      transform: isVisible
                        ? "translateX(0)"
                        : "translateX(200px)",
                    }}
                    config={config.molasses}
                  >
                    {props => (
                      <div
                        style={{
                          ...props,
                        }}
                        className={styles.oneThird}
                      >
                        <div className={styles.imageContainer}>
                          <img
                            src={gallery3}
                            alt="About memorial"
                            width="100%"
                          ></img>
                        </div>
                      </div>
                    )}
                  </Spring>
                )}
              </VisibilitySensor>
            </Col>
          </Row>
          <Row style={{textAlign:"center", justifyContent:"center"}}>
            <Col xs={12} md={6} lg={4}>
              <VisibilitySensor partialVisibility>
                {({ isVisible }) => (
                  <Spring
                    delay={300}
                    to={{
                      opacity: isVisible ? 1 : 0,
                      transform: isVisible
                        ? "translateX(0)"
                        : "translateX(-200px)",
                    }}
                    config={config.molasses}
                  >
                    {props => (
                      <div
                        style={{
                          ...props,
                        }}
                        className={styles.oneThird}
                      >
                        <div className={styles.imageContainer}>
                          <img
                            src={gallery4}
                            alt="About memorial"
                            width="100%"
                          ></img>
                        </div>
                      </div>
                    )}
                  </Spring>
                )}
              </VisibilitySensor>
            </Col>
            <Col xs={12} md={6} lg={4}>
              <VisibilitySensor partialVisibility>
                {({ isVisible }) => (
                  <Spring
                    delay={300}
                    to={{
                      opacity: isVisible ? 1 : 0,
                      transform: isVisible
                        ? "translateY(0)"
                        : "translateY(200px)",
                    }}
                    config={config.molasses}
                  >
                    {props => (
                      <div
                        style={{
                          ...props,
                        }}
                        className={styles.oneThird}
                      >
                        <div className={styles.imageContainer}>
                          <img
                            src={gallery5}
                            alt="About memorial"
                            width="100%"
                          ></img>
                        </div>
                      </div>
                    )}
                  </Spring>
                )}
              </VisibilitySensor>
            </Col>
            <Col xs={12} md={6} lg={4}>
              <VisibilitySensor partialVisibility>
                {({ isVisible }) => (
                  <Spring
                    delay={300}
                    to={{
                      opacity: isVisible ? 1 : 0,
                      transform: isVisible
                        ? "translateX(0)"
                        : "translateX(200px)",
                    }}
                    config={config.molasses}
                  >
                    {props => (
                      <div
                        style={{
                          ...props,
                        }}
                        className={styles.oneThird}
                      >
                        <div className={styles.imageContainer}>
                          <img
                            src={gallery6}
                            alt="About memorial"
                            width="100%"
                          ></img>
                        </div>
                      </div>
                    )}
                  </Spring>
                )}
              </VisibilitySensor>
            </Col>
          </Row>
        </Container>
      </div> */}
        </Layout>
      </ClientOnly>
    </div>
  )
}

//highlight-start
export const pageQuery = graphql`
  query {
    allWpPage(sort: { fields: [date] }) {
      nodes {
        title
        slug
      }
    }
  }
`
//highlight-end
